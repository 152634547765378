import axios from "axios";
import { history } from "../../shared/react-router.utils";

import { baseUrl } from "../reducers/usersReducer";

import { isNotEmptyString, isNumber } from "../../shared/utils-ts";
import {
  ALL_EVENTS_ID,
  CREATE_FLOOR_FILLED_VIDEO,
  CREATED_AT,
  TRANSCRIPTION,
  UPDATED_AT,
} from "../../shared/constants";

import { setGlobalError } from "./errorAction";
import {
  DEFAULT_SORT,
  DEFAULT_TIMEZONE,
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_NUMBER,
  DEFAULT_DATERANGE,
} from "../../shared/constants";

import {
  GET_EVENTS_PER_PAGE,
  ADD_EVENT_TO_CATEGORY,
  SEARCHED_EVENTS_PER_PAGE,
  RESET_SEARCH_VALUE,
  LOAD_EVENTS,
  GET_EVENT_FILES_FOR_DOWNLOAD,
  DOWNLOADING_EVENT_FILE,
  FETCH_RECORDING_INSTANCES,
  GET_RECORDING_INSTANCES,
  GET_EVENT,
  GET_RECORDING_INSTANCE,
  LOAD_EVENT,
  CHANGE_NO_DATA_REDIRECT,
  GET_SELECTED_RECORDING_INSTANCE,
  SET_TRANSCRIPTION_STATUS,
  ON_ERROR_REDIRECT,
  SET_FLOOR_FILLED_VIDEO_STATUS,
  CHANGE_EVENT_LIST,
  CHANGE_CLEAR_DATE,
  GET_RECORDING_INSTANCE_TRANSCRIPTION_STATUS,
  TOGGLE_EVENT_FILES_LIST,
  SET_MEDIA_FILE_OFFSET,
  TOGGLE_SHOW_SEGMENTED_FILES,
  CHANGE_RECORDING_INSTANCE,
  DELETE_RECORDING_INSTANCE,
} from "./types";
import { Dispatch } from "redux";
import {
  IEvent,
  IEventItem,
  IRecordingInstance,
  ISort,
} from "../../models/events";
import { ICategory } from "../../models/categories";

export const searchEventsPerPage =
  (
    searchQuery = "",
    categoryId = "0",
    pageNumber = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    sort = DEFAULT_SORT,
    timezone = DEFAULT_TIMEZONE,
    dateRange = DEFAULT_DATERANGE
  ) =>
    async (dispatch: Dispatch) => {
      let params = new URLSearchParams();
      let eventsUri = `${baseUrl}/events`;
      if (searchQuery || categoryId) {
        eventsUri = `${baseUrl}/events/search`;
        appendSearchQueryIfNotEmptyToQueryParams(searchQuery, params);
        appendCategoryIdIfNotEmptyToQueryParams(categoryId, params);
      }

      appendPageNumberIfNotEmptyToQueryParams(pageNumber, params);
      appendPageSizeOrDefaultValueToQueryParams(pageSize, params);
      appendSortOrDefaultToQueryParams(sort, params);

      if (dateRange.fromDate && dateRange.toDate) {
        appendDatesIfNotEmptyToQueryParams(
          dateRange.fromDate,
          dateRange.toDate,
          params
        );
      }

      return axios
        .get(eventsUri, { params })
        .then((res) => {
          const { content, totalElements, totalPages, pageable } = res.data;
          return dispatch({
            type: SEARCHED_EVENTS_PER_PAGE,
            payload: {
              events: content,
              totalEvents: totalElements,
              totalPages: totalPages,
              pageNumber: pageNumber === 0 ? 1 : pageable.pageNumber + 1,
              searchInput: searchQuery,
              sortedBy: {
                id: sort.id,
                value: sort.value,
                direction: sort.direction,
              },
              timezone: {
                id: timezone.id,
                value: timezone.value,
              },
              dateRange: dateRange,
            },
          });
        })
        .catch((err) => {
          setGlobalError(err, dispatch);
        });
    };

export const getEventsPerPage =
  (
    categoryId = "0",
    pageNumber = 0,
    pageSize = DEFAULT_PAGE_SIZE,
    sort = DEFAULT_SORT,
    timezone = DEFAULT_TIMEZONE
  ) =>
    async (dispatch: Dispatch) => {
      let params = new URLSearchParams();
      let eventsUri = `${baseUrl}/events`;

      if (categoryId !== ALL_EVENTS_ID) {
        eventsUri = `${baseUrl}/categories/${categoryId}/events`;
      }
      appendPageNumberIfNotEmptyToQueryParams(pageNumber, params);
      appendPageSizeOrDefaultValueToQueryParams(pageSize, params);
      appendSortOrDefaultToQueryParams(sort, params);
      return axios
        .get(eventsUri, { params })
        .then((res) => {
          const { content, totalElements, totalPages, pageable } = res.data;
          return dispatch({
            type: GET_EVENTS_PER_PAGE,
            payload: {
              events: content,
              totalEvents: totalElements,
              totalPages: totalPages,
              pageNumber: pageNumber === 0 ? 1 : pageable.pageNumber + 1,
              sortedBy: {
                id: sort.id,
                value: sort.value,
                direction: sort.direction,
              },
              timezone: {
                id: timezone.id,
                value: timezone.value,
              },
            },
          });
        })
        .catch((err) => {
          setGlobalError(err, dispatch);
        });
    };

function appendCategoryIdIfNotEmptyToQueryParams(
  categoryId: string,
  params: URLSearchParams
) {
  if (isNotEmptyString(categoryId)) {
    if (categoryId !== "0") {
      params.append("category", categoryId);
    }
  }
}

const appendDatesIfNotEmptyToQueryParams = (
  fromDate: string,
  toDate: string,
  params: URLSearchParams
) => {
  if (isNotEmptyString(fromDate)) {
    params.append("fromDate", fromDate);
  }
  if (isNotEmptyString(toDate)) {
    params.append("toDate", toDate);
  }
};

function appendSortOrDefaultToQueryParams(
  sort: ISort,
  params: URLSearchParams
) {
  if (
    sort === undefined ||
    !sort.hasOwnProperty("value") ||
    !sort.hasOwnProperty("direction")
  ) {
    sort = DEFAULT_SORT;
  }
  if (sort.value === CREATED_AT) {
    params.append("sort", `${UPDATED_AT},${sort["direction"]}`);
  } else {
    params.append("sort", `${sort["value"]},${sort["direction"]}`);
  }
  return sort;
}

function appendSearchQueryIfNotEmptyToQueryParams(
  searchQuery: string | "",
  params: URLSearchParams
) {
  if (isNotEmptyString(searchQuery)) {
    params.append("query", searchQuery);
  }
}

function appendPageNumberIfNotEmptyToQueryParams(
  pageNumber: number,
  params: URLSearchParams
) {
  if (isNumber(pageNumber)) {
    params.append("page", pageNumber as any);
  } else {
    params.append("page", DEFAULT_PAGE_NUMBER as any);
  }
}

function appendPageSizeOrDefaultValueToQueryParams(
  pageSize: number,
  params: URLSearchParams
) {
  if (isNumber(pageSize)) {
    params.append("size", pageSize as any);
  } else {
    params.append("size", DEFAULT_PAGE_SIZE as any);
  }
}

export const addEventToCategory =
  (eventId: string, categoryId: string, selectedCategory: ICategory) =>
    async (dispatch: Dispatch) => {
      if (eventId && categoryId) {
        return axios
          .patch(`${baseUrl}/events/${eventId}/category`, { categoryId })
          .then((res) =>
            dispatch({
              type: ADD_EVENT_TO_CATEGORY,
              payload: { eventId, selectedCategory },
            })
          )
          .catch((err) => setGlobalError(err, dispatch));
      }
    };

export const resetSearchValue =
  (selectedCategory?: ICategory) => async (dispatch: Dispatch) => {
    return dispatch({
      type: RESET_SEARCH_VALUE,
      payload: { selectedCategory },
    });
  };

export const loadEvents = () => async (dispatch: Dispatch) => {
  return dispatch({ type: LOAD_EVENTS });
};
export const loadEvent = () => async (dispatch: Dispatch) => {
  return dispatch({ type: LOAD_EVENT });
};

export const downloadEventFile =
  (eventFileId: string, isDownloadingEventFile: boolean) =>
    async (dispatch: Dispatch) => {
      dispatch({
        type: DOWNLOADING_EVENT_FILE,
        payload: { eventFileId, isDownloadingEventFile },
      });
    };

export const getEventFilesForDownload =
  (event: IEventItem, recordingInstanceId: string, hasParams = false, isInterval: boolean = false) =>
    async (dispatch: Dispatch) => {
      return axios
        .get(
          `${baseUrl}/events/${event.id}/recordings/${recordingInstanceId}/files`
        )
        .then((response) => {
          return dispatch({
            type: GET_EVENT_FILES_FOR_DOWNLOAD,
            payload: {
              event,
              eventFilesForDownload: response.data,
              recordingInstanceId,
              hasParams,
              isInterval
            },
          });
        })
        .catch((error) => {
          setGlobalError(error, dispatch);
        });
    };

export const toggleEventFilesList = (event: IEventItem, recordingInstance: IRecordingInstance ) => async (dispatch: Dispatch) => {
  dispatch({ type: TOGGLE_EVENT_FILES_LIST, payload: { event, recordingInstance } });
};

export const getRecordingInstances =
  (event: IEvent, hasParams = true) =>
    async (dispatch: Dispatch) => {
      dispatch({type: FETCH_RECORDING_INSTANCES, payload: { event }});
      return axios
        .get(`${baseUrl}/events/${event.id}/recordings`)
        .then((response) => {
          return dispatch({
            type: GET_RECORDING_INSTANCES,
            payload: {
              recordingInstances: response.data,
              event,
              hasParams,
            },
          });
        })
        .catch((error) => setGlobalError(error, dispatch));
    };

export const getEvent = (eventId: string) => async (dispatch: any) => {
  return axios
    .get(`${baseUrl}/events/${eventId}`)
    .then((response) => {
      return dispatch({
        type: GET_EVENT,
        payload: {
          event: response.data,
        },
      });
    })
    .catch((error) => {
      setGlobalError(error, dispatch);
      dispatch(onErrorRedirect());
    });
};

export const getRecordingInstance =
  (event: IEventItem, recordingInstanceId: string) => async (dispatch: any) => {
    return axios
      .get(`${baseUrl}/events/${event.id}/recordings/${recordingInstanceId}`)
      .then((response) => {
        return dispatch({
          type: GET_RECORDING_INSTANCE,
          payload: {
            recordingInstance: response.data,
            event,
          },
        });
      })
      .catch((error) => {
        setGlobalError(error, dispatch);
        dispatch(onErrorRedirect());
      });
  };
export const getRecordingInstanceTranscriptionStatus =
  (event: IEventItem, recordingInstanceId: string) => async (dispatch: any) => {
    return axios
      .get(
        `${baseUrl}/events/${event.id}/recordings/${recordingInstanceId}/transcript_status`
      )
      .then((response) => {
        return dispatch({
          type: GET_RECORDING_INSTANCE_TRANSCRIPTION_STATUS,
          payload: {
            transcriptions: response.data.transcriptions,
          },
        });
      })
      .catch((error) => {
        setGlobalError(error, dispatch);
        dispatch(onErrorRedirect());
      });
  };
export const onErrorRedirect = () => async (dispatch: Dispatch) => {
  await dispatch({ type: ON_ERROR_REDIRECT });
  history.push("/media-library");
};
export const changeRecordingInstance =
  (event: IEventItem, recordingInstanceId: string, body: {}) => (dispatch: Dispatch) => {
    return axios.patch(`${baseUrl}/events/${event.id}/recordings/${recordingInstanceId}`, body)
    .then((response) => {
      return dispatch({
        type: CHANGE_RECORDING_INSTANCE,
        payload: {
          recordingInstance: response.data
        },
      });
    })
    .catch((error) => {
      setGlobalError(error, dispatch);
    });
}
export const deleteRecordingInstance =
  (event: IEventItem, recordingInstanceId: string) => (dispatch: Dispatch) => {
    return axios.delete(`${baseUrl}/events/${event.id}/recordings/${recordingInstanceId}`)
    .then(() => {
      return dispatch({ type: DELETE_RECORDING_INSTANCE, payload: { recordingInstanceId }});
    })
    .catch((error) => {
      setGlobalError(error, dispatch);
    });
}
export const changeNoDataRedirect = () => async (dispatch: Dispatch) => {
  dispatch({ type: CHANGE_NO_DATA_REDIRECT });
};
export const changeShowEventList =
  (showEventList: boolean) => async (dispatch: Dispatch) => {
    return dispatch({
      type: CHANGE_EVENT_LIST,
      payload: { showEventList },
    });
  };

export const changeClearDate =
  (clearDate: boolean) => async (dispatch: Dispatch) => {
    return dispatch({
      type: CHANGE_CLEAR_DATE,
      payload: { clearDate },
    });
  };

export const generateTranscription =
  (
    event: IEventItem,
    selectedRecordingInstance: IRecordingInstance,
    transcriptionLanguagesSelected: string[],
    isFullLengthTranscription?: boolean,
    floorLanguageCode?: string,
  ) =>
    async (dispatch: Dispatch) => {
      return axios
        .post(
          `${baseUrl}/events/${event.id}/recordings/${selectedRecordingInstance.id}/process`,
          {
            type: TRANSCRIPTION,
            floorLanguageCode: floorLanguageCode,
            interpreterLanguageCodes: transcriptionLanguagesSelected,
            fullLengthTranscription: isFullLengthTranscription,
          }
        )
        .then((response) => {
          return dispatch({
            type: SET_TRANSCRIPTION_STATUS,
            payload: {
              selectedRecordingInstance,
            },
          });
        })
        .catch((err) => {
          setGlobalError(err, dispatch);
        });
    };

export const getSelectedRecordingInstance =
  (recordingInstance: IRecordingInstance) => (dispatch: Dispatch) => {
    return dispatch({
      type: GET_SELECTED_RECORDING_INSTANCE,
      payload: { recordingInstance },
    });
  };

export const generateFloorFilledVideo =
  (event: IEventItem, selectedRecordingInstance: IRecordingInstance) =>
    async (dispatch: Dispatch) => {
      return axios
        .post(
          `${baseUrl}/events/${event.id}/recordings/${selectedRecordingInstance.id}/process`,
          {
            type: CREATE_FLOOR_FILLED_VIDEO,
          }
        )
        .then((response) => {
          return dispatch({
            type: SET_FLOOR_FILLED_VIDEO_STATUS,
            payload: {
              selectedRecordingInstance,
            },
          });
        })
        .catch((err) => {
          setGlobalError(err, dispatch);
        });
    };

export const setMediaFileOffset = (evenId: string, recordingInstancId: string, eventFileId: string, offsetInSeconds: number) =>
  async (dispatch: Dispatch) => {
    return axios.patch(
      `${baseUrl}/events/${evenId}/recordings/${recordingInstancId}/files/${eventFileId}/offset/${offsetInSeconds}`
    )
      .then((response) => {
        return dispatch({
          type: SET_MEDIA_FILE_OFFSET,
          payload: {
            eventFileId,
            offsetInSeconds
          }
        })
      })
      .catch((err) => {
        setGlobalError(err, dispatch);
      });
  }

export const toggleShowSegmentedFiles = () => async (dispatch: Dispatch) => {
  dispatch({
    type: TOGGLE_SHOW_SEGMENTED_FILES
  });
}
