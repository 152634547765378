import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// import SortBy from "./SortBy/SortBy";
// import Timezone from "./Timezone/Timezone";
import EventItem from "./EventItem/EventItem";
import * as S from "../../styled";
import {
  loadEvents,
  searchEventsPerPage,
} from "../../store/actions/eventAction";
import { RootState } from "../../store/store";
import { IUrlParams } from "../../models/navigation";
import { IEventItem, ISort, ITimezone } from "../../models/events";
import { keycloakSetup } from "../../shared/keycloack";
import RecordingInstances from "./RecordingInstances/RecordingInstances";
import Loader from "../Loader/Loader";

const Events = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const events = useSelector((state: RootState) => state.event.events);
  const totalPages = useSelector((state: RootState) => state.event.totalPages);
  const totalEvents = useSelector(
    (state: RootState) => state.event.totalEvents
  );
  const pageNumber = useSelector((state: RootState) => state.event.pageNumber);
  const sortedBy = useSelector((state: RootState) => state.event.sortedBy);
  const timezone = useSelector((state: RootState) => state.event.timezone);
  const searchValue = useSelector(
    (state: RootState) => state.event.searchValue
  );
  const dateRange = useSelector((state: RootState) => state.event.dateRange);
  const isLoadingEvents = useSelector((state: RootState) => state.event.isLoadingEvents);
  const selectedCategory = useSelector(
    (state: RootState) => state.category.selectedCategory
  );
  const gotOrganizations = useSelector(
    (state: RootState) => state.user.gotOrganizations
  );
  const selectedOrganization = useSelector(
    (state: RootState) => state.user.selectedOrganization
  );
  const showEventList = useSelector(
    (state: RootState) => state.event.showEventList
  );

  const [isLastElement, setIsLastElement] = useState(false);
  let { eventId } = useParams<IUrlParams>();

  useEffect(() => {
    if (gotOrganizations && !searchValue && !eventId) {
      triggerLoadEvents(sortedBy, timezone, undefined);
    }
    // eslint-disable-next-line
  }, [selectedOrganization, eventId, showEventList]);

  const isAuthorized = async () => {
    const keyclockObject = await keycloakSetup;
    const { keycloak } = keyclockObject;
    return keycloak;
  };

  const triggerLoadEvents = async (
    sortedBy: ISort,
    timezone: ITimezone,
    pageNumber: number | undefined
  ) => {
    if (await isAuthorized()) {
      await dispatch(loadEvents());
      await dispatch(
        searchEventsPerPage(
          searchValue,
          selectedCategory.id,
          pageNumber,
          undefined,
          sortedBy,
          timezone,
          dateRange
        )
      );
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    if (totalPages === pageNumber) setIsLastElement(true);
    if (
      Math.floor(scrollHeight - scrollTop) === clientHeight ||
      Math.ceil(scrollHeight - scrollTop) === clientHeight
    ) {
      if (totalPages >= pageNumber && events.length !== totalEvents) {
        triggerLoadEvents(sortedBy, timezone, pageNumber);
      }
    }
  };

  /*const changeSort = (sorted: ISort) => {
    if (eventId) {
      redirectToMainRoute(history, undefined, eventId, undefined);
    } else {
      triggerLoadEvents(sorted, timezone, undefined);
    }
  }; */

  /*const changeTimezone = (timezone: ITimezone) => {
    if (eventId) {
      redirectToMainRoute(history, undefined, eventId, undefined);
    } else {
      triggerLoadEvents(sortedBy, timezone, undefined);
    }
  }; */

  function displayMessage(searchValue: string) {
    return searchValue || (dateRange?.fromDate && dateRange?.toDate)
      ? t("events.searchCategoryNoResult")
      : t("events.categoryEmpty");
  }

  return (
    <>
      {showEventList && (
        <>
          {/* <SortBy handleChangeSort={(sort: ISort) => changeSort(sort)} />
            <Timezone
            handleChangeTimeZone={(time: ITimezone) => changeTimezone(time)}
            /> */}
          <S.EventsTableBody
            width="100%"
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            padding="0"
          >
            <S.EventsList
              height={searchValue}
              onScroll={(e) => handleScroll(e)}
              isLastElementInTheList={isLastElement}
              data-testid="events-ul"
              tabIndex="0"
            >
              {events.length !== 0 && events.map((event: IEventItem) => (
                <EventItem key={event.id} event={event} data-testid="event" />
              ))}

              {events.length === 0 && !isLoadingEvents && (
                <S.CategoryEmptyMsg>
                  {displayMessage(searchValue)}
                </S.CategoryEmptyMsg>
              )}
            </S.EventsList>
            {isLoadingEvents && (
              <S.LoadingBlock
                width="calc(100% - 12px)"
                height="calc(100% - 65px)"
                padding="0"
              >
                <Loader />
              </S.LoadingBlock>
            )}
          </S.EventsTableBody>
        </>
      )}
      {!showEventList && <RecordingInstances />}
    </>
  );
};

export default Events;
