import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import DownloadEventFiles from "../../DownloadEventFiles/DownloadEventFiles";
import ConfirmDialog from "../../../../shared/ConfirmDialog/ConfirmDialog";

import ValidationMessage from '../../../ValidationMessage/ValidationMessage';
import dropDown from "../../../../assets/icons/chevron-down.svg";
import dropDownHover from "../../../../assets/icons/chevron-down-orange.svg";

import {
  generateTranscription,
  getEventFilesForDownload,
  generateFloorFilledVideo,
  getRecordingInstanceTranscriptionStatus,
  toggleEventFilesList,
  changeRecordingInstance,
  deleteRecordingInstance,
} from "../../../../store/actions/eventAction";
import * as S from "../../../../styled";
import { RootState } from "../../../../store/store";
import {
  IEventItem,
  IRecordingInstanceItem,
  ItemStatus,
  ITranscriptionStatus,
  RecordingInstanceStatus,
} from "../../../../models/events";
import LanguageSelect from "./LanguageSelect/LanguageSelect";
import {
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { Delete, Mode, MoreVert } from "@mui/icons-material";
import { DecodeLanguageShortCode } from "../../../../shared/Language";
import palette from "../../../../material-ui/theme/colors";
import { timeout } from "../../../../shared/utils-ts";
import Loader from "../../../Loader/Loader";

const RecordingInstancesItem = ({
  recordingInstance,
}: RecordingInstancesItemProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selectedEvent = useSelector(
    (state: RootState) => state.event.selectedEvent as IEventItem
  );
  const eventFilesForDownload = useSelector(
    (state: RootState) => state.event.eventFilesForDownload
  );
  const selectedRecordingInstance = useSelector(
    (state: RootState) => state.event.selectedRecordingInstance
  );
  const isLoadingEventFilesForDownload = useSelector(
    (state: RootState) => state.event.isLoadingEventFilesForDownload
  );
  const env = useSelector((state: RootState) => state.user.environment);
  const [activeDialog, setActiveDialog] = useState({
    isOpen: false,
    confirmDialogType: "",
  });
  const [isSelected, setIsSelected] = useState(false);
  const [transcriptLanguage, setTranscriptLanguage] = useState("");
  const [isGenerateTranscript, setIsGenerateTranscript] = useState(false);
  const [transcriptionLanguagesAvailable, setTranscriptionLanguagesAvailable] =
    useState<ITranscriptionStatus[]>([]);
  const [allTranscriptionLanguagesSelect, setAllTranscriptionLanguagesSelect] =
    useState(true);
  const [nameHover, setNameHover] = useState<boolean>();
  const [isChecked, setIsChecked] = useState(true);
  const [currentFloorLanguage, setCurrentFloorLanguage] = useState("");
  const [nameInputValue, setNameInputValue] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpened = Boolean(anchorEl);

  const handleCheckbox = () => {
    const val = isChecked;
    setIsChecked(!val);
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const InstanceRenameComponent = () => (
    <>
      <Stack sx={{marginBottom: 1}}>
        <FormControl>
          <OutlinedInput
            id="outlined-name"
            error={nameInputValue.length > 50 || !nameInputValue.length}
            data-testid="outlined-name"
            value={nameInputValue}
            onChange={(changeEvent: React.ChangeEvent<HTMLInputElement>) =>
              setNameInputValue(changeEvent.target.value)
            }
            placeholder={t("tooltip.renameRecordPlaceholder")}
            color="primary"
            inputProps={{
              "aria-label": "search event",
              style: {
                fontSize: "16px",
                paddingLeft: 0.5,
                color: palette.grey[100],
                paddingTop: 12.5,
                paddingBottom: 12.5,
              },
            }}
            sx={{
              pl: 1.5,
              "& input": {
                "&::placeholder": {
                  color: palette.grey[80],
                  opacity: 1,
                },
              },
            }}
            autoFocus
          />
          {(nameInputValue.length > 50 || !nameInputValue.length) &&
            <ValidationMessage
              message={
                !nameInputValue.length
                  ? t("error.emptyInput")
                  : t("error.maxLength50")
              }
            />
          }
        </FormControl>
      </Stack>
    </>
  )

  const LanguageSelectComponent = () => (
    <>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="left"
        spacing={2}
      >
        <Typography variant="h5" paddingTop={1}>
          {t("events.fromSource")}
        </Typography>
        <LanguageSelect
          handleSelect={setIsSelected}
          setLanguage={setTranscriptLanguage}
        />
        {currentFloorLanguage !== "" &&
          transcriptLanguage !== "multi" &&
          transcriptLanguage !== "other" &&
          transcriptLanguage !== "" && (
            <Typography variant="p1" sx={{ mt: 1 }} color={"red"}>
              {t("events.transcriptionOverwrite")}
            </Typography>
          )}
        <Typography variant="p1_grey">{t("events.languageSelect")}</Typography>
        <Stack
          direction="row"
          justifyContent="start"
          alignItems="center"
          mt={3}
          onClick={handleCheckbox}
          sx={{
            cursor: "pointer",
          }}
        >
          <Checkbox
            disableRipple
            disableTouchRipple
            disableFocusRipple
            checked={isChecked}
            sx={{ "& .MuiSvgIcon-root": { fontSize: 28 }, ml: -0.3, p: 0 }}
          />
          <Typography variant="p1" sx={{ pl: 1 }}>
            {t("events.generateFullLength")}
          </Typography>
        </Stack>
      </Stack>
      {transcriptionLanguagesAvailable.length > 0 ? (
        <>
          <Typography variant="h5" sx={{ mt: 3 }}>
            {t("events.fromInterpretation")}
          </Typography>
          <Stack direction="row" flexWrap={"wrap"} sx={{ mt: 1 }}>
            <Chip
              id="all_items"
              label="All"
              color={allTranscriptionLanguagesSelect ? "secondary" : "primary"}
              onClick={updateAllLanguageData}
              variant="modal"
            ></Chip>
            {transcriptionLanguagesAvailable.map((data) => {
              return (
                <Chip
                  id={data.sessionId}
                  label={DecodeLanguageShortCode(data.languageCode)}
                  color={data.selected ? "secondary" : "primary"}
                  onClick={updateLanguageData(data)}
                  variant="modal"
                />
              );
            })}
          </Stack>
        </>
      ) : (
        <Typography
          variant="p1"
          align="left"
          color="secondary"
          sx={{ mt: 3, display: "block" }}
        >
          {t("events.noAdditionalLanguageAvailable")}
        </Typography>
      )}
    </>
  );
  const updateLanguageData = (data: ITranscriptionStatus) => () => {
    const opt = transcriptionLanguagesAvailable.filter((x) => {
      if (x.sessionId === data.sessionId) x.selected = !x.selected;
      return x;
    });
    setTranscriptionLanguagesAvailable(opt);
    checkAllLanguage();
  };
  const updateAllLanguageData = () => {
    if (!allTranscriptionLanguagesSelect) {
      setTranscriptionLanguagesAvailable(
        transcriptionLanguagesAvailable.filter((x) => {
          x.selected = false;
          return x;
        })
      );
      setAllTranscriptionLanguagesSelect(true);
    }
  };
  const checkAllLanguage = () => {
    if (transcriptionLanguagesAvailable.find((x) => x.selected === true))
      setAllTranscriptionLanguagesSelect(false);
    else setAllTranscriptionLanguagesSelect(true);
  };
  const filterTranscriptionLanguages = () => {
    let filteredLanguages: string[] = [];
    if (allTranscriptionLanguagesSelect) {
      transcriptionLanguagesAvailable.filter((x) => {
        filteredLanguages.push(x.languageCode);
        return x;
      });
    } else {
      transcriptionLanguagesAvailable.filter((x) => {
        if (x.selected === true) filteredLanguages.push(x.languageCode);
        return x;
      });
    }
    return filteredLanguages;
  };
  const dialogNotificationText = () => {
    switch (transcriptLanguage) {
      case "other":
      case "multi":
        return (
          <Typography variant="p1" align="left" color="secondary">
            {t("events.dialogNotificationOther")}
          </Typography>
        );
      default:
        return (
          <Typography variant="p1" align="left" color="secondary">
            {t("events.dialogNotificationDefault")}
          </Typography>
        );
    }
  };

  const confirmDialogProps = {
    transcription: {
      onConfirm: async () => {
        let result;
        const transcriptionLanguagesSelected = filterTranscriptionLanguages();
        if (transcriptLanguage === "other" || transcriptLanguage === "multi") {
          result = await dispatch(
            generateTranscription(
              selectedEvent,
              selectedRecordingInstance,
              transcriptionLanguagesSelected,
              isChecked
            )
          );
        } else {
          result = await dispatch(
            generateTranscription(
              selectedEvent,
              selectedRecordingInstance,
              transcriptionLanguagesSelected,
              isChecked,
              transcriptLanguage
            )
          );
        }
        if (result !== undefined) {
          setIsGenerateTranscript(true);
          await timeout(1000);
          await updateEventFileList(true);
        }
        setIsSelected(false);
      },
      settings: {
        title: "main.transcription",
        body: LanguageSelectComponent(),
        isConfirm: isSelected,
      },
    },
    languageFloorFilledVideo: {
      onConfirm: () => {
        dispatch(
          generateFloorFilledVideo(selectedEvent, selectedRecordingInstance)
        );
      },
      settings: {
        title: t("confirmDialog.generateFloorFilledVideo"),
        isConfirm: true,
      },
    },
    recordingInstanceNameChanging: {
      onConfirm: () => {
        dispatch(changeRecordingInstance(
          selectedEvent,
          recordingInstance.id,
          { name: nameInputValue }
        ));
        setNameInputValue(recordingInstance.name);
      },
      settings: {
        title: t("tooltip.editRecordingName"),
        isConfirm: nameInputValue.length !== 0 && nameInputValue.length <= 50,
        body: InstanceRenameComponent(),
      },
    },
    deleteInstance: {
      onConfirm: () => {
        dispatch(deleteRecordingInstance(
          selectedEvent,
          recordingInstance.id,
        ));
      },
      settings: {
        title: t("confirmDialog.areYouSureDelete"),
        isConfirm: true,
        maxWidth: 'sm',
        isDelete: true,
        body: (
          <Typography variant="p1" color="secondary" align="left" sx={{mt: 3, mb: 2}}>
            {t("confirmDialog.willDeletePermanently")}
          </Typography>
        ),
      },
    },
  };

  const toggleDownloadEventFiles = async (
    recordingInstance: IRecordingInstanceItem
  ) => {
    await dispatch(toggleEventFilesList(selectedEvent, recordingInstance));
    if (!recordingInstance.isExpanded) {
      await updateEventFileList();
    }
  };

  const updateEventFileList = async (isInterval: boolean = false) => {
    await dispatch(
      getEventFilesForDownload(selectedEvent, recordingInstance.id, false, isInterval)
    );
    const riTranscriptionResponse = await dispatch(
      getRecordingInstanceTranscriptionStatus(
        selectedEvent,
        recordingInstance.id
      )
    );
    //@ts-ignore
    const tempArray = riTranscriptionResponse?.payload.transcriptions
      ?.filter(
        (ts: ITranscriptionStatus) =>
          (ts.transcriptionStatus === ItemStatus.AVAILABLE ||
            ts.transcriptionStatus === ItemStatus.FAILED) &&
          ts.floor === false
      )
      .map((ts: ITranscriptionStatus) => ({ ...ts, selected: false }));
    // @ts-ignore
    const floorLanguage = riTranscriptionResponse?.payload.transcriptions.find(
      (ts: ITranscriptionStatus) => ts.floor === true
    );
    setCurrentFloorLanguage(floorLanguage?.languageCode || "");

    setTranscriptionLanguagesAvailable(tempArray || []);
  };

  const generateTranscriptionHandler = () => {
    setActiveDialog({
      isOpen: true,
      confirmDialogType: "transcription",
    });
  };

  const generateVideoHandler = () => {
    setActiveDialog({
      isOpen: true,
      confirmDialogType: "languageFloorFilledVideo",
    });
  };
 
  const generateRenameInstanceHandler = (event: React.MouseEvent<HTMLElement>) => {
    handleMenuClose(event);
    setNameInputValue(recordingInstance.name);
    setActiveDialog({
      isOpen: true,
      confirmDialogType: "recordingInstanceNameChanging",
    });
  };

  const removeInstanceHandler = (event: React.MouseEvent<HTMLElement>) => {
    handleMenuClose(event);
    setActiveDialog({
      isOpen: true,
      confirmDialogType: "deleteInstance",
    });
  };

  function checkContextMenuItemStatus(status: ItemStatus): boolean {
    return (
      selectedRecordingInstance.mediaFilesCount === 0 ||
      !(status === "AVAILABLE" || status === "FAILED")
    );
  }

  function getItemHrefRS2(): string {
    if (eventFilesForDownload.length > 0) {
      const urlFromFile =
        eventFilesForDownload[0].path?.split("/processed/")[0];
      return `https://s3.console.aws.amazon.com/s3/buckets/medialib-mediactrl-${env}?prefix=${urlFromFile}/processed_rs2/&showversions=false`;
    } else return "";
  }

  const [isTranscriptUnavailable, setIsTranscriptUnavailable] = useState(false);
  useEffect(() => {
    if (!!selectedRecordingInstance) {
      setIsTranscriptUnavailable(
        selectedRecordingInstance.mediaFilesCount === 0 ||
        selectedRecordingInstance.status !== RecordingInstanceStatus.PROCESSED
      );
    } else setIsTranscriptUnavailable(false);
  }, [selectedRecordingInstance]);

  return (
    <>
      {activeDialog.isOpen && (
        <ConfirmDialog
          onCancel={() => {
            setActiveDialog({
              isOpen: false,
              confirmDialogType: "",
            });
            setTranscriptLanguage("");
          }}
          onConfirm={
            (confirmDialogProps as any)[activeDialog.confirmDialogType]
              .onConfirm
          }
          settings={
            (confirmDialogProps as any)[activeDialog.confirmDialogType].settings
          }
        />
      )}
      {isGenerateTranscript && (
        <ConfirmDialog
          onCancel={() => {
            setIsGenerateTranscript(false);
          }}
          onConfirm={() => {}}
          settings={{
            title: "Transcription",
            body: dialogNotificationText(),
            isConfirm: true,
            isCancelBtn: false,
          }}
        />
      )}
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        width={"100%"}
        py={2}
        onClick={() => toggleDownloadEventFiles(recordingInstance)}
        onMouseEnter={() => setNameHover(true)}
        onMouseLeave={() => setNameHover(false)}
        onBlur={() => setNameHover(false)}
        sx={{
          "&:hover": {
            color: palette.brand.main,
            "& #menu-button": {
              visibility: "visible"
            }
          },
          cursor: "pointer",
          position: "relative",
        }}
      >
        <Typography variant="h2" style={{
          maxWidth: 'calc(100% - 90px)',
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis"
        }}>
          {recordingInstance.name}
        </Typography>
        <S.DropDownIcon
          src={nameHover ? dropDownHover : dropDown}
          alt="dropdown icon"
          open={recordingInstance.isExpanded}
          padding="0"
        />
        {isLoadingEventFilesForDownload && recordingInstance.isExpanded && (
          <S.IconWrap><Loader isDownloading={true} /></S.IconWrap> )}
        <IconButton
          id="menu-button"
          data-testid="open-menu-button"
          aria-controls={isMenuOpened ? "recording-instance-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={isMenuOpened ? "true" : undefined}
          onClick={handleMenuOpen}
          color="secondary"
          size="large"
          disableRipple
          sx={{
            visibility: isMenuOpened ? 'visible' : 'hidden',
            position: "absolute",
            right: 15,
            top: "50%",
            transform: "translateY(-50%)",
            "& .MuiSvgIcon-root": {
              width: 24,
              height: 24,
              borderRadius: "50%",
              borderStyle: "solid",
              borderWidth: "2px",
              borderColor: palette.brand.main,
            },
          }}
        >
          <MoreVert sx={{ color: palette.brand.main }}></MoreVert>
        </IconButton>
        <Menu
          id="recording-instance-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={isMenuOpened}
          onClose={handleMenuClose}
        >
          <MenuItem
            key={"edit"}
            onClick={generateRenameInstanceHandler}
            sx={{".MuiListItemIcon-root": {minWidth: 20} }}
          >
            <ListItemIcon>
              <Mode color="secondary" fontSize="medium" />
            </ListItemIcon>
            <Typography variant="p1_bold" align="left">
              {t("tooltip.editName")}
            </Typography>
          </MenuItem>
          <MenuItem
            key={"remove"}
            onClick={removeInstanceHandler}
            sx={{".MuiListItemIcon-root": {minWidth: 20} }}
          >
            <ListItemIcon>
              <Delete color="secondary" fontSize="medium" />
            </ListItemIcon>
            <Typography variant="p1_bold" align="left">
              {t("tooltip.removeRecording")}
            </Typography>
          </MenuItem>
        </Menu>
      </Stack>
      {recordingInstance.isExpanded && (
        <>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
          >
            <Button
              disableRipple
              variant="outlined"
              color="secondary"
              onClick={generateTranscriptionHandler}
              disabled={isTranscriptUnavailable}
              data-testid="transcription-button"
            >
              <Typography variant="p1_bold" align="center">
                {isTranscriptUnavailable
                  ? t("events.generateTranscriptionUnavailable")
                  : t("events.generateTranscription")}
              </Typography>
            </Button>
            <Button
              disableRipple
              variant="outlined"
              color="secondary"
              onClick={generateVideoHandler}
              disabled={checkContextMenuItemStatus(
                recordingInstance.languageFloorFilledVideoStatus
              )}
              data-testid="language-button"
            >
              <Typography variant="p1_bold" align="center">
                {selectedRecordingInstance.mediaFilesCount === 0
                  ? t("events.generateVideoUnavailable")
                  : t("events.generateVideo")}
              </Typography>
            </Button>
            {eventFilesForDownload.length > 0 && (
              <Typography variant="p3_bold">
                <a
                  title="RS2"
                  target="_blank"
                  rel="noreferrer"
                  href={getItemHrefRS2()}
                >
                  RS2 Files
                </a>
              </Typography>
            )}
          </Stack>

          {eventFilesForDownload.length > 0 && (
            <DownloadEventFiles timestamp={recordingInstance.createdAt} updateEventFileList={updateEventFileList} />
          )}
        </>
      )}
      <Divider sx={{ paddingTop: recordingInstance.isExpanded ? "16px" : "0" }}/>
    </>
  );
};

interface RecordingInstancesItemProps {
  recordingInstance: IRecordingInstanceItem;
}
export default RecordingInstancesItem;
