import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  changeShowEventList,
  getRecordingInstances,
} from "../../../store/actions/eventAction";
import { IEventItem } from "../../../models/events";
import { Stack, Paper, Typography } from "@mui/material";
import palette from "../../../material-ui/theme/colors";
import { formatName } from "../../../shared/utils-ts";
import { formatEventDate } from "../../../shared/utils-events";

const EventItem = ({ event }: EventItemProps) => {
  const dispatch = useDispatch();
  const ref = useRef<any | null>(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () =>
      document.removeEventListener("click", handleClickOutside, true);
    // eslint-disable-next-line
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current?.contains(event.target)) {
    }
  };

  const onEventClick = (event: IEventItem) => {
    dispatch(getRecordingInstances(event, false));
    dispatch(changeShowEventList(false));
  };

  return (
    <Paper elevation={0} sx={{ p: 3, my: 1, borderRadius: "16px" }}>
      <Stack direction="column" justifyContent="center" alignItems="flex-start">
        <Typography
          variant="h2"
          sx={{
            "&:hover": {
              color: palette.brand.main,
            },
            cursor: "pointer",
          }}
          onClick={() => onEventClick(event)}
        >
          {formatName(event.name, 50)}
        </Typography>
        <Typography variant="p1" sx={{ color: palette.grey[80], mt: 1 }}>
          {"Last recording on "}
          {formatEventDate(event.updatedAt)}
        </Typography>
      </Stack>
    </Paper>
  );
};

interface EventItemProps {
  event: IEventItem;
}
export default EventItem;
