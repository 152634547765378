import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Options, useToasts } from "react-toast-notifications";

import SearchBar from "../../components/SearchBar/SearchBar";
import Events from "../../components/Events/Events";

import { RootState } from "../../store/store";
import {
  Button,
  Checkbox,
  Container,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IEventItem } from "../../models/events";
import { redirectToMainRoute } from "../../shared/react-router.utils";
import {
  changeShowEventList,
  getEvent,
  getEventFilesForDownload,
  getRecordingInstance,
  getRecordingInstances,
  loadEvent,
  resetSearchValue,
  toggleShowSegmentedFiles,
} from "../../store/actions/eventAction";
import { useHistory, useParams } from "react-router-dom";
import { IUrlParams } from "../../models/navigation";
import backSource from "../../assets/icons/back.svg";
import palette from "../../material-ui/theme/colors";
import SearchChip from "../../components/Events/SearchChip/SearchChip";
import { keycloakSetup } from "../../shared/keycloack";
import {
  changeOrganizationAccess,
  setBaseUrl,
} from "../../store/actions/userAction";
import { FlexDiv } from "../../styled";

const Main = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();

  const theme = useTheme();
  const isFHD = useMediaQuery(theme.breakpoints.up("xl"));
  const toastOpt: Options = { appearance: "error", autoDismiss: true };
  const errorObj = useSelector((state: RootState) => state.error?.errorObj);
  const showEventList = useSelector(
    (state: RootState) => state.event.showEventList
  );

  const eventData = useSelector(
    (state: RootState) => state.event.selectedEvent
  );

  const selectedOrganization = useSelector(
    (state: RootState) => state.user.selectedOrganization
  );
  const userData = useSelector((state: RootState) => state.user.data);
  const eventName = eventData;
  const { organizationId, eventId, recordingInstanceId }: IUrlParams =
    useParams();

  const checkShowSegmentedFiles = useSelector(
    (state: RootState) => state.event.showSegmentedFiles
  );
  const handleCheckbox = () => {
    dispatch(toggleShowSegmentedFiles());
  };

  useEffect(() => {
    if (errorObj?.errorMsg)
      addToast(
        `${errorObj.errorMsg}
        ${errorObj.errorDetails ? `- ${errorObj.errorDetails}` : ""}`,
        toastOpt
      );
    // eslint-disable-next-line
  }, [errorObj]);

  useEffect(() => {
    findOrganizationFromParam(organizationId);
    !organizationId && setOrganization(selectedOrganization);
    if (eventId) {
      triggerLoadEvent(eventId);
    }
    // eslint-disable-next-line
  }, []);

  const backToEventList = () => {
    dispatch(changeShowEventList(true));
    if (eventId) {
      dispatch(resetSearchValue());
      redirectToMainRoute(history, "current", undefined, undefined);
    }
  };

  function findOrganizationFromParam(organizationId: string | undefined) {
    if (organizationId) {
      let organization = userData?.organizations?.find(
        (organization: any) => organizationId === organization.id
      );
      if (organization) {
        setOrganization(organization);
      } else {
        setDefaultOrganization();
      }
    }
  }
  async function setOrganization(selectedOrganization: any) {
    await dispatch(changeOrganizationAccess(selectedOrganization));
  }
  function setDefaultOrganization() {
    dispatch(setBaseUrl());
    if (organizationId !== undefined)
      history.push(
        history.location.pathname.slice().replace(organizationId, "current")
      );
  }
  const triggerLoadEvent = async (eventId: string) => {
    if (await isAuthorized()) {
      await dispatch(loadEvent());
      const eventDispatched = (await dispatch(getEvent(eventId))) as any;
      const selectedEvent = eventDispatched?.payload.event as IEventItem;
      if (selectedEvent) {
        if (recordingInstanceId) {
          await loadRecordingInstance(selectedEvent);
        } else {
          dispatch(changeShowEventList(false));
          await dispatch(getRecordingInstances(selectedEvent));
        }
      } else {
        dispatch(changeShowEventList(true));
      }
    }
  };
  const loadRecordingInstance = async (selectedEvent: IEventItem) => {
    const recordingInstanceDispatched = (await dispatch(
      getRecordingInstance(selectedEvent, recordingInstanceId)
    )) as any;
    if (recordingInstanceDispatched) {
      dispatch(changeShowEventList(false));
      dispatch(
        getEventFilesForDownload(selectedEvent, recordingInstanceId, true)
      );
    } else {
      dispatch(changeShowEventList(true));
    }
  };
  const isAuthorized = async () => {
    const keyclockObject = await keycloakSetup;
    const { keycloak } = keyclockObject;
    return keycloak;
  };

  return (
    <>
      <Stack width={"100%"} height={"100%"} sx={{ overflow: "hidden" }}>
        <Stack width={"100%"} sx={{ background: palette.grey[0] }}>
          <Container maxWidth={isFHD ? "xl" : "lg"} sx={{ p: 3 }}>
            {!showEventList && (
              <Button
                variant="text"
                disableRipple
                onClick={() => backToEventList()}
                startIcon={<img src={backSource} alt="back icon" />}
              >
                <Typography variant="p1_bold"> {t("main.back")}</Typography>
              </Button>
            )}
            {
              // <Typography variant="h2" pb={2}>
              //   {showEventList
              //     ? "Recorded events"
              //     : (eventName as IEventItem).name}
              // </Typography>

              showEventList ? (
                <Typography variant="h2" pb={2}>
                  Recorded events
                </Typography>
              ) : (
                <FlexDiv width="100%" justifyContent="space-between">
                  <Typography variant="h2" pb={2}>
                    {(eventName as IEventItem).name}
                  </Typography>
                  <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                    onClick={handleCheckbox}
                    sx={{
                      cursor: "pointer",
                      paddingBottom: 1,
                    }}
                  >
                    <Typography variant="p1_bold" sx={{ pl: 1 }}>
                      {t("events.showSegmentedFiles")}
                    </Typography>
                    <Checkbox
                      disableRipple
                      disableTouchRipple
                      disableFocusRipple
                      checked={checkShowSegmentedFiles}
                      sx={{
                        "& .MuiSvgIcon-root": { fontSize: 28 },
                        p: 0,
                      }}
                    />
                  </Stack>
                </FlexDiv>
              )
            }
            {showEventList && <SearchBar />}
            {showEventList && <SearchChip />}
          </Container>
        </Stack>
        <Stack width={"100%"} height={"calc(100% - 210px)"} mt={1}>
          <Container maxWidth={isFHD ? "xl" : "lg"} sx={{ height: "100%" }}>
            <Events />
          </Container>
        </Stack>
      </Stack>
    </>
  );
};

export default Main;
