
// SETUP COLORS

const BRAND = {
  main: "#FF5D1F",
  main_10: "#ff5d1f1a",
  90: '#FF7945',
  120: "#C94714",
};

const GREEN = {
  120: "#00954F",
};

const GREY = {
  0: "#FFFFFF",
  5: "#F8F8F8",
  10: "#E7E7E7",
  20: "#D4D4D4",
  60: "#ABABAB",
  80: "#7F7E7E",
  90: "#504D4D",
  100: "#2A2828",
  102: "#2a282833"
};

const RED = {
  80: "#FF7878",
  100: "#FF5353",
};

const COMMON = {
  common: {
    black: "#000",
    white: "#fff",
    text: "#212B36",
    para: "#637381",
    nav: "#454F5B",
    grey: "#F4F6F8",
  },
  grey: GREY,
  brand: BRAND,
  green: GREEN,
  action: {
    hover: "#FF7945",
    selected: "#C94714",
    disabled: "#FFF",
    disabledBackground: "#FF5D1F80",
    focus: "#FF7945",
    hoverOpacity: 0.08,
    disabledOpacity: 0.5,
  },
};

const palette = {
  ...COMMON,
  primary: BRAND,
  secondary: { main: GREY[100] },
  text: { primary: GREY[100] },
  action: { ...COMMON.action },
  red: RED
};

export default palette;
